import React from "react";

function Spinner() {
  return (
    <div>
      <div>
        <div>Waiting for the blockchain...</div>
      </div>
      <p></p>
    </div>
  );
}

export default Spinner;
